import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Location } from "@/core/model/location";

export interface LocationList {
  page: number;
  limit: number;
  total: number;
  items: Array<Location>;
}
export interface LocationInfo {
  location: Location;
  list: LocationList;
}

@Module
export default class LocationModule extends VuexModule implements LocationInfo {
  location = {} as Location;
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };

  @Mutation
  [Mutations.ADMIN_LOCATION_UPDATE_LIST](list) {
    this.list = list;
  }

  @Action({rawError: true})
  [Actions.ADMIN_LOCATION_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/locations";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_LOCATION_UPDATE_LIST, data);
          resolve(data);
        })
        .catch(({ error }) => {
          console.log("ERROR1", error);
          reject(error);
        });
    });
  }
}
