import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { decodeResponseErrors } from "@/core/helpers/errors";
import { CatalogProduct } from "@/core/model/CatalogProduct";
import { CatalogBrand } from "@/core/model/CatalogBrand";
import { CatalogCategory } from "@/core/model/CatalogCategory";

export interface ProductList {
  page: number;
  limit: number;
  total: number;
  items: Array<CatalogProduct>;
}

export interface BrandList {
  page: number;
  limit: number;
  total: number;
  items: Array<CatalogBrand>;
}

export interface CategoryList {
  page: number;
  limit: number;
  total: number;
  items: Array<CatalogCategory>;
}

export interface CatalogInfo {
  products: ProductList;
  brands: BrandList;
  categories: CategoryList;
  success: boolean;
  errors: Array<string>;
}

@Module
export default class CatalogModule extends VuexModule implements CatalogInfo {
  products = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  brands = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  categories = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  success = false;
  errors = [];

  @Mutation
  [Mutations.CATALOG_UPDATE_PRODUCTS](list) {
    this.products = list;
  }

  @Mutation
  [Mutations.CATALOG_UPDATE_BRANDS](list) {
    this.brands = list;
  }

  @Mutation
  [Mutations.CATALOG_UPDATE_CATEGORIES](list) {
    this.categories = list;
  }

  @Mutation
  [Mutations.CATALOG_SET_ERROR](error) {
    this.errors = error;
  }

  @Action({rawError: true})
  [Actions.CATALOG_PRODUCT_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/catalog/products";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
          .then(({ data }) => {
            this.context.commit(Mutations.CATALOG_UPDATE_PRODUCTS, data);
            resolve(data);
          })
          .catch(({ response }) => {
            const errors = decodeResponseErrors(response);
            this.context.commit(Mutations.CATALOG_SET_ERROR, errors);
            reject(response);
          });
    });
  }

  @Action({rawError: true})
  [Actions.CATALOG_BRAND_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/catalog/catalog-brands";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.CATALOG_UPDATE_BRANDS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.CATALOG_SET_ERROR, errors);
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.CATALOG_CATEGORY_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/catalog/catalog-categories";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.CATALOG_UPDATE_CATEGORIES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.CATALOG_SET_ERROR, errors);
          reject(response);
        });
    });
  }


}
