import ApiService from "@/core/services/ApiService";
import JwtService, { decodeToken } from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ToastService from "@/core/services/ToastService";
import { decodeResponseErrors } from "@/core/helpers/errors";
import type { AuthUser } from "@/core/model/AuthUser";

export interface UserAuthInfo {
  errors: unknown;
  user: AuthUser;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as AuthUser;
  isAuthenticated = !!JwtService.getToken();
  success = false;

  /**
   * Get user data
   * @returns AuthUser
   */
  get getUser() {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  get isAdmin() {
    return this.user?.roles?.includes("ROLE_ADMIN");
  }

  get isMutualAdmin() {
    return this.user?.roles?.includes("ROLE_ADMIN_MUTUAL");
  }

  get isEmployee() {
    return this.user?.roles?.includes("ROLE_USER_MUTUAL");
  }

  get userRole() {
    return this.user?.roles && this.user?.roles.length > 0 ? this.user?.roles[0] : "";
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.RECOVER_SUCCESS](response) {
    this.success = response;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.errors = [];
    JwtService.saveToken(user.token);
  }

  @Mutation
  [Mutations.LOAD_USER_FROM_TOKEN]() {
    const decoded = decodeToken() as AuthUser;
    if(decoded) {
      this.user.firstname = decoded.firstname;
      this.user.lastname = decoded.lastname;
      this.user.email = decoded.email;
      this.user.username = decoded.username;
      this.user.roles = decoded.roles;
      this.user.phone = decoded.phone;
      this.user.mutual = decoded.mutual;
    }
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as AuthUser;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("login_check", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          this.context.commit(Mutations.LOAD_USER_FROM_TOKEN);
          resolve();
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.RECOVER](formData) {
    return new Promise<void>((resolve, reject) => {
      const resource = "auth/recover-password";
      ApiService.setHeader();

      ApiService.post(resource, formData)
        .then(({ data }) => {
          this.context.commit(Mutations.RECOVER_SUCCESS, true);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.RECOVER_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_PASSWORD](formData) {
    return new Promise<void>((resolve, reject) => {
      const resource = "auth/update-password";
      ApiService.setHeader();

      ApiService.post(resource, formData)
        .then(({ data }) => {
          this.context.commit(Mutations.RECOVER_SUCCESS, true);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.RECOVER_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.VALIDATOR_TOKEN](formData) {
    return new Promise<void>((resolve, reject) => {
      const resource = "auth/validator-password";
      ApiService.setHeader();

      ApiService.post(resource, formData)
        .then(({ data }) => {
          this.context.commit(Mutations.RECOVER_SUCCESS, true);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.RECOVER_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.VERIFY_AUTH](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("users/verify-token", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          this.context.commit(Mutations.LOAD_USER_FROM_TOKEN);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }
}
