import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { IUser, User } from "@/core/model/User";
import { decodeResponseErrors } from "@/core/helpers/errors";

export interface UserList {
  page: number;
  limit: number;
  total: number;
  items: User[];
}

export interface UserInfo {
  baseUrl: string;
  list: UserList;
  user: User;
  success: boolean;
  errors: Array<string>;
}

@Module
export default class UsersModule extends VuexModule implements UserInfo {
  baseUrl = "/users";
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [] as User[],
  };
  success = false;
  errors = [];
  user = {} as User;

  @Mutation
  [Mutations.USERS_UPDATE_LIST](list: UserList) {
    this.list.page = list.page;
    this.list.total = list.total;
    this.list.limit = list.limit;
    this.list.items = [];
    list.items.forEach((user: IUser) => {
      this.list.items.push(new User(user));
    });
  }

  @Mutation
  [Mutations.USERS_UPDATE_ONE](data: User) {
    this.user = data;
  }

  @Mutation
  [Mutations.USERS_SUCCESS](response) {
    this.success = response;
  }

  @Mutation
  [Mutations.USERS_SET_ERROR](error) {
    this.errors = error;
  }

  @Action
  [Actions.USERS_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = this.baseUrl;
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.USERS_UPDATE_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.USERS_SET_ERROR, errors);
          this.context.commit(Mutations.USERS_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.USERS_CREATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = this.baseUrl;
      ApiService.setHeader();
      ApiService.post(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.USERS_SUCCESS, true);
          this.context.commit(Mutations.USERS_UPDATE_ONE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.USERS_SET_ERROR, errors);
          this.context.commit(Mutations.USERS_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.USERS_UPDATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = this.baseUrl + "/" + data.user;
      ApiService.setHeader();
      ApiService.put(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.USERS_SUCCESS, true);
          //this.context.commit(Mutations.PROFILING_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.USERS_SET_ERROR, errors);
          this.context.commit(Mutations.USERS_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.USERS_GET](uuid: string) {
    return new Promise<void>((resolve, reject) => {
      const resource = this.baseUrl;
      ApiService.setHeader();
      ApiService.get(resource, uuid)
        .then(({ data }) => {
          this.context.commit(Mutations.USERS_SUCCESS, true);
          this.context.commit(Mutations.USERS_UPDATE_ONE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.USERS_SET_ERROR, errors);
          this.context.commit(Mutations.USERS_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.LOAD_DATA_PROFILE](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = '/users/profile';
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.USERS_SUCCESS, true);
          this.context.commit(Mutations.USERS_UPDATE_ONE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.USERS_SET_ERROR, errors);
          this.context.commit(Mutations.USERS_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_PROFILE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = '/users/updateUsers';
      ApiService.setHeader();
      ApiService.put(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.USERS_SUCCESS, true);
          this.context.commit(Mutations.USERS_UPDATE_ONE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.USERS_SET_ERROR, errors);
          this.context.commit(Mutations.USERS_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_PASSWORD_PROFILE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = '/users/updatePasswordProfile';
      ApiService.setHeader();
      ApiService.put(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.USERS_SUCCESS, true);
          this.context.commit(Mutations.USERS_UPDATE_ONE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.USERS_SET_ERROR, errors);
          this.context.commit(Mutations.USERS_SUCCESS, false);
          reject(response);
        });
    });
  }

}
