import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Brand } from "@/core/model/Brand";
import { decodeResponseErrors } from "@/core/helpers/errors";

export interface BrandList {
  page: number;
  limit: number;
  total: number;
  items: Array<Brand>;
}
export interface BrandInfo {
  list: BrandList;
  success: boolean;
}

@Module
export default class BrandModule
    extends VuexModule
    implements BrandInfo
{
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  success = false;

  @Mutation
  [Mutations.BRAND_UPDATE_LIST](list) {
    this.list = list;
  }

  @Mutation
  [Mutations.BRAND_SUCCESS](response) {
    this.success = response;
  }

  @Action({rawError: true})
  [Actions.BRAND_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/catalog/brands";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
          .then(({ data }) => {
            this.context.commit(Mutations.BRAND_UPDATE_LIST, data);
            resolve(data);
          })
          .catch(({ response }) => {
            const errors = decodeResponseErrors(response);
            this.context.commit(Mutations.SET_ERROR, errors);
            this.context.commit(Mutations.BRAND_SUCCESS, false);
            reject(response);
          });
    });
  }
}
