import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Order } from "@/core/model/Orders";
import ToastService from "@/core/services/ToastService";
import { decodeResponseErrors } from "@/core/helpers/errors";

export interface OrderList {
  page: number;
  limit: number;
  total: number;
  items: Array<Order>;
}
export interface OrderInfo {
  order: Order;
  list: OrderList;
  success: boolean;
  errors: Array<string>;
}

@Module
export default class OrdersModule extends VuexModule implements OrderInfo {
  order = {} as Order;
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  success = false;
  errors = [];

  @Mutation
  [Mutations.ORDER_UPDATE_LIST](list) {
    this.list = list;
  }

  @Mutation
  [Mutations.ORDER_UPDATE_DATA](data) {
    this.order = data;
  }

  @Mutation
  [Mutations.ORDER_CREATE](response) {
    this.success = response;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Action
  [Actions.ORDER_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/loan-requests";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.ORDER_UPDATE_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          //this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.ORDER_GET](uuid) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/loan-requests";
      ApiService.setHeader();
      ApiService.get(resource, uuid)
        .then(({ data }) => {
          this.context.commit(Mutations.ORDER_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.ORDER_CREATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/loan-requests";
      ApiService.setHeader();
      ApiService.post(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.ORDER_CREATE, true);
          this.context.commit(Mutations.ORDER_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.ORDER_CREATE, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.ORDER_UPDATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/loan-requests/" + data.order;
      ApiService.setHeader();
      ApiService.put(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.ORDER_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }

  @Action
  async [Actions.ORDER_UPDATE_STATE](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = `/loan-requests/${params.order}/update-status`;
      ApiService.setHeader();
      ApiService.put(resource, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }
}
