import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import PaymentPlansModule from "@/store/modules/PaymentPlansModule";
import OrdersModule from "@/store/modules/OrdersModule";
import MutualesModule from "@/store/modules/MutualesModule";
import UsersModule from "@/store/modules/UsersModule";
import ProductsModule from "@/store/modules/ProductsModule";
import TaxonomyTermModule from "@/store/modules/TaxonomyTermModule";
import CategoryModule from "@/store/modules/CategoryModule";
import BrandModule from "@/store/modules/BrandModule";
import LocationModule from "@/store/modules/LocationModule";
import AdminMutualesModule from "@/store/modules/AdminMutualesModule";
import AdminProductsModule from "@/store/modules/AdminProductsModule";
import AdminSiteModule from "@/store/modules/AdminSiteModule";
import CatalogModule from "@/store/modules/CatalogModule";
import ToolbarActionsModule from "@/store/modules/ToolbarActionsModule";
import DashboardModule from "@/store/modules/DashboardModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    PaymentPlansModule,
    OrdersModule,
    MutualesModule,
    UsersModule,
    ProductsModule,
    TaxonomyTermModule,
    CategoryModule,
    BrandModule,
    LocationModule,
    AdminMutualesModule,
    AdminProductsModule,
    AdminSiteModule,
    CatalogModule,
    ToolbarActionsModule,
    DashboardModule,
  },
});

export default store;
