import { User } from "@/core/model/User";
import { CommonModel } from "@/core/model/Common";
import { UtilsModel } from "@/core/model/Utils";

interface IPaymentPlan {
  uuid: string;
  name: string;
  description: string;
  interest_rate: number;
  max_quotas: number;
  minimal_price: number;
  created_at: string;
  updated_at: string;
  created_by: User;
  updated_by: User;
  active: boolean;
  installment_amount?: number;
}

class PaymentPlan extends CommonModel implements IPaymentPlan {
  uuid = "";
  name = "";
  description = "";
  interest_rate = 0;
  max_quotas = 0;
  minimal_price = 0;
  created_by = {} as User;
  updated_by = {} as User;
  installment_amount = 0;

  //constructor(active: boolean, created_at: string, created_by: User, description: string, interest_rate: number, max_quotas: number, minimal_price: number, modified_at: string, name: string, updated_by: User, uuid: string);
  constructor(data: IPaymentPlan) {
    super();
    this.load(data);
  }

  load(data: IPaymentPlan) {
    this.uuid = data.uuid;
    this.active = data.active;
    this.created_at = data.created_at;
    this.description = data.description;
    this.interest_rate = data.interest_rate;
    this.max_quotas = data.max_quotas;
    this.minimal_price = data.minimal_price;
    this.updated_at = data.updated_at;
    this.name = data.name;
    this.created_by = new User(data.created_by);
    if (data.updated_by) {
      this.updated_by = new User(data.updated_by);
    }
  }

  getInterestRateFormatted() {
    return this.interest_rate + "% EA";
  }

  getMinimalPriceFormatted() {
    return UtilsModel.formatAmount(this.minimal_price);
  }
}
export { IPaymentPlan, PaymentPlan };
