import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { decodeResponseErrors } from "@/core/helpers/errors";
import { DataStat1 } from "@/core/model/DataStat1";

export interface DashboardInfo {
  statOrderCount: DataStat1;
  statOrderIncome: DataStat1;
  errors: Array<string>;
}

@Module
export default class DashboardModule extends VuexModule implements DashboardInfo {
  statOrderCount = {
    total: 0,
    detail: [],
  };
  statOrderIncome = {
    total: 0,
    detail: [],
  };
  errors = [];

  @Mutation
  [Mutations.DB_UPDATE_ORDERS_COUNT](data) {
    this.statOrderCount = data;
  }

  @Mutation
  [Mutations.DB_UPDATE_ORDERS_INCOME](data) {
    this.statOrderIncome = data;
  }

  @Action
  [Actions.DB_ORDERS_COUNT](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/dashboard/stat-orders-count";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.DB_UPDATE_ORDERS_COUNT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.DB_ORDERS_INCOME](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/dashboard/stat-orders-income";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.DB_UPDATE_ORDERS_INCOME, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }

}
