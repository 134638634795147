import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutual } from "@/core/model/mutual";
import { decodeResponseErrors } from "@/core/helpers/errors";

interface ICatalog {
  brands: Array<string>;
  categories: Array<Array<string>>;
}

export interface MutualList {
  page: number;
  limit: number;
  total: number;
  items: Array<Mutual>;
}
export interface MutualInfo {
  mutual: Mutual;
  list: MutualList;
  success: boolean;
  errors: Array<string>;
  catalog: ICatalog;
}

@Module
export default class AdminMutualesModule extends VuexModule implements MutualInfo {
  mutual = {} as Mutual;
  catalog = {} as ICatalog;
  mutualSettings = [];
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  success = false;
  errors = [];

  @Mutation
  [Mutations.ADMIN_MUTUAL_UPDATE_LIST](list) {
    this.list = list;
  }

  @Mutation
  [Mutations.ADMIN_MUTUAL_UPDATE_CATALOG](data) {
    this.catalog = data;
  }

  @Mutation
  [Mutations.ADMIN_MUTUAL_UPDATE_DATA](data) {
    this.mutual = data;
  }

  @Mutation
  [Mutations.ADMIN_MUTUAL_SUCCESS](response) {
    this.success = response;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.ADMIN_MUTUAL_UPDATE_SETTINGS](data) {
    this.mutualSettings = data;
  }

  @Action({rawError: true})
  [Actions.ADMIN_MUTUAL_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/mutuales";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_MUTUAL_UPDATE_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.MUTUAL_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_MUTUAL_GET](uuid) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/mutuales";
      ApiService.setHeader();
      ApiService.get(resource, uuid)
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_MUTUAL_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ error }) => {
          console.log("ERROR1", error);
          reject(error);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_MUTUAL_CREATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/mutuales";
      ApiService.setHeader();
      ApiService.post(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, true);
          this.context.commit(Mutations.ADMIN_MUTUAL_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_MUTUAL_UPDATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/mutuales/" + data.mutual;
      ApiService.setHeader();
      ApiService.put(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, true);
          this.context.commit(Mutations.ADMIN_MUTUAL_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_MUTUAL_CHANGE_STATUS](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/mutuales/" + data.mutual + '/change-status';
      ApiService.setHeader();
      ApiService.put(resource, data)
          .then(() => {
            this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, true);
            resolve();
          })
          .catch(({ response }) => {
            const errors = decodeResponseErrors(response);
            this.context.commit(Mutations.SET_ERROR, errors);
            this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, false);
            reject(response);
          });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_MUTUAL_GET_SETTINGS](uuid) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/mutuales";
      ApiService.setHeader();
      ApiService.get(resource, uuid + "/settings")
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_MUTUAL_UPDATE_SETTINGS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_MUTUAL_SAVE_SETTINGS](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/mutuales/" + data.mutual + "/settings";
      ApiService.setHeader();
      ApiService.put(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, true);
          this.context.commit(Mutations.ADMIN_MUTUAL_UPDATE_SETTINGS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_MUTUAL_GET_CATALOG](uuid) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/mutuales";
      ApiService.setHeader();
      ApiService.get(resource, uuid + "/catalog")
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_MUTUAL_UPDATE_CATALOG, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_MUTUAL_TAX_TERMS_UPDATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/mutual-taxonomy-terms/";
      ApiService.setHeader();
      ApiService.put(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, true);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.ADMIN_MUTUAL_SUCCESS, false);
          reject(response);
        });
    });
  }

}
