import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.isAdmin) {
        next();
      } else {
        next({ name: "404" });
      }
    },
    children: [
      {
        path: "/admin/dashboard",
        name: "admin_dashboard",
        component: () => import("@/views/dashboards/AdminDashboard.vue"),
      },
      //Mutuales
      {
        path: "/admin/mutuales",
        name: "admin_mutuales",
        component: () => import("@/views/mutuales/MutualList.vue"),
      },
      {
        path: "/admin/mutuales/:uuid/profile",
        name: "admin_mutual_profile",
        component: () => import("@/views/mutuales/MutualProfile.vue"),
      },
      {
        path: "/admin/mutuales/:uuid/settings",
        name: "mutual-settings",
        component: () => import("@/views/mutuales/MutualSettings.vue"),
      },
      {
        path: "/admin/mutuales/:uuid/catalog",
        name: "admin_mutual_profile_catalog",
        component: () => import("@/views/mutuales/MutualCatalog.vue"),
      },
      {
        path: "/admin/mutuales/:uuid/site",
        name: "admin_mutual_profile_site",
        component: () => import("@/views/mutuales/MutualSite.vue"),
      },
      {
        path: "/admin/mutuales/:uuid/payment-plans",
        name: "admin_mutual_profile_payments",
        component: () => import("@/views/mutuales/MutualPaymentPlans.vue"),
      },
      //Orders - Creditos
      {
        path: "/admin/orders",
        name: "admin_orders",
        component: () => import("@/views/orders/OrderList.vue"),
      },
      {
        path: "/admin/orders/:uuid/details",
        name: "admin_orders_details",
        component: () => import("@/views/orders/OrderDetail.vue"),
      },
      //users
      {
        path: "/admin/users",
        name: "admin_users",
        component: () => import("@/views/users/UsersList.vue"),
      },
      {
        path: "/admin/users/new",
        name: "admin_users_new",
        component: () => import("@/views/users/UsersForm.vue"),
      },
      {
        path: "/admin/users/:uuid",
        name: "admin_users_edit",
        component: () => import("@/views/users/UsersForm.vue"),
      },
      //Catalog
      {
        path: "/admin/catalog/products",
        name: "admin_products",
        component: () => import("@/views/catalog/ProductsList.vue"),
      },
      {
        path: "/admin/catalog/categories",
        name: "admin_categories",
        component: () => import("@/views/catalog/CategoriesList.vue"),
      },
      {
        path: "/admin/catalog/brands",
        name: "admin_brands",
        component: () => import("@/views/catalog/BrandsList.vue"),
      },
    ],
  },
  {
    path: "/mutual",
    redirect: "/mutual/dashboard",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.isMutualAdmin || store.getters.isEmployee) {
        next();
      } else {
        next({ name: "404" });
      }
    },
    children: [
      {
        path: "/mutual/dashboard",
        name: "mutual_dashboard",
        component: () => import("@/views/dashboards/MutualDashboard.vue"),
      },
      {
        path: "/mutual/payment-plans",
        name: "mutual_paymentplans",
        component: () => import("@/views/payment-plans/PaymentPansList.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.isMutualAdmin) {
            next();
          } else {
            next({ name: "404" });
          }
        },
      },
      {
        path: "/mutual/payment-plans/new",
        name: "mutual_paymentplans_new",
        component: () => import("@/views/payment-plans/PaymentPlanForm.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.isMutualAdmin) {
            next();
          } else {
            next({ name: "404" });
          }
        },
      },
      {
        path: "/mutual/payment-plans/:uuid",
        name: "mutual_paymentplans_edit",
        component: () => import("@/views/payment-plans/PaymentPlanForm.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.isMutualAdmin) {
            next();
          } else {
            next({ name: "404" });
          }
        },
      },
      {
        path: "/mutual/users",
        name: "mutual_users",
        component: () => import("@/views/users/UsersList.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.isMutualAdmin) {
            next();
          } else {
            next({ name: "404" });
          }
        },
      },
      {
        path: "/mutual/users/new",
        name: "mutual_users_new",
        component: () => import("@/views/users/UsersForm.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.isMutualAdmin) {
            next();
          } else {
            next({ name: "404" });
          }
        },
      },
      {
        path: "/mutual/users/:uuid",
        name: "mutual_users_edit",
        component: () => import("@/views/users/UsersForm.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.isMutualAdmin) {
            next();
          } else {
            next({ name: "404" });
          }
        },
      },
      {
        path: "/mutual/orders",
        name: "mutual_orders",
        component: () => import("@/views/orders/OrderList.vue"),
      },
      {
        path: "/mutual/orders/new",
        name: "mutual_orders_new",
        component: () => import("@/views/orders/OrderForm.vue"),
      },
      {
        path: "/mutual/orders/:uuid/edit",
        name: "mutual_orders_edit",
        component: () => import("@/views/orders/OrderForm.vue"),
      },
      {
        path: "/mutual/orders/:uuid/details",
        name: "mutual_orders_detail",
        component: () => import("@/views/orders/OrderDetail.vue"),
      },
      {
        path: "/mutual/profile",
        name: "mutual_profile",
        component: () => import("@/views/mutual/MutualProfile.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.isMutualAdmin) {
            next();
          } else {
            next({ name: "404" });
          }
        },
      },
      {
        path: "/mutual/catalog",
        name: "mutual_catalog",
        component: () => import("@/views/mutual/MutualCatalog.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.isMutualAdmin) {
            next();
          } else {
            next({ name: "404" });
          }
        },
      },
      {
        path: "/mutual/site",
        name: "mutual_site",
        component: () => import("@/views/mutual/MutualSite.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.isMutualAdmin) {
            next();
          } else {
            next({ name: "404" });
          }
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/profile",
        name: "user_profile",
        component: () => import("@/views/authentication/ProfileUser.vue"),
      },
      {
        path: "/profile/edit",
        name: "user_profile_edit",
        component: () => import("@/views/authentication/ProfileEdit.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/auth/update-password/:token",
        name: "update-password",
        component: () => import("@/views/authentication/UpdatePassword.vue"),
      },
      {
        path: "/auth/recover-password",
        name: "recover-password",
        component: () => import("@/views/authentication/RecoverPassword.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/errors/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/errors/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  //carga la info del usuario del token almacenado
  if (store.getters.isUserAuthenticated) {
    store.commit(Mutations.LOAD_USER_FROM_TOKEN);
  }
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (JwtService.getToken()) {
    store
      .dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() })
      .then((data) => {
        //console.log('thenn', data);
      })
      .catch((response) => {
        store
          .dispatch(Actions.LOGOUT)
          .then(() => router.push({ name: "sign-in" }));
        console.log("catch", response);
      });
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
