import { ref } from "vue";
import type { ModuleActionItem } from "@/core/model/ModuleActionItem";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface DateRangeInfo {
  date: string;
  active: boolean;
}
interface ActionsInfo {
  buttons: Array<ModuleActionItem>;
  date_range_active: boolean;
  date_range_value: Array<string>;
  filter_mutual_active: boolean;
  filter_mutual_value: string;
}
@Module
export default class ToolbarActionsModule extends VuexModule implements ActionsInfo {
  buttons = [];
  date_range_active = false;
  date_range_value = [];
  filter_mutual_active = false;
  filter_mutual_value = "";

  get showDateRange(): boolean {
    return this.date_range_active;
  }

  get getDateRangeValue(): Array<string> {
    return this.date_range_value;
  }

  get getFilterMutualValue(): string {
    return this.filter_mutual_value;
  }

  get showFilterMutual(): boolean {
    return this.filter_mutual_active;
  }

  @Mutation
  [Mutations.TA_CONFIGURE_MUTATION](config) {
    this.buttons = config.buttons;
    this.date_range_active = config.date_range_active;
    this.date_range_value = config.date_range_value;
    this.filter_mutual_active = config.filter_mutual_active;
    this.filter_mutual_value = config.filter_mutual_value;
  }

  @Mutation
  [Mutations.TA_UPDATE_DATE_RANGE_MUTATION](value) {
    this.date_range_value = value;
  }

  @Mutation
  [Mutations.TA_UPDATE_FILTER_MUTUAL_MUTATION](value) {
    this.filter_mutual_value = value;
  }

  @Action
  [Actions.TA_CONFIGURE_ACTION](config) {
    this.context.commit(Mutations.TA_CONFIGURE_MUTATION, config);
  }

  @Action
  [Actions.TA_UPDATE_DATE_RANGE_ACTION](value) {
    this.context.commit(Mutations.TA_UPDATE_DATE_RANGE_MUTATION, value);
  }

  @Action
  [Actions.TA_UPDATE_FILTER_MUTUAL_ACTION](value) {
    this.context.commit(Mutations.TA_UPDATE_FILTER_MUTUAL_MUTATION, value);
  }
}
