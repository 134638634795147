import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaymentPlan, IPaymentPlan } from "@/core/model/PaymentPlan";
import { decodeResponseErrors } from "@/core/helpers/errors";

export interface PaymentPlanList {
  page: number;
  limit: number;
  total: number;
  items: PaymentPlan[];
}

export interface PaymentPlanInfo {
  baseUrl: string;
  list: PaymentPlanList;
  plan: PaymentPlan;
  success: boolean;
  errors: Array<string>;
}

@Module
export default class PaymentPlansModule
  extends VuexModule
  implements PaymentPlanInfo
{
  baseUrl = "/mutual/payment-plans";
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [] as PaymentPlan[],
  };
  success = false;
  errors = [];
  plan = {} as PaymentPlan;

  @Mutation
  [Mutations.PAYMENT_PLANS_UPDATE_LIST](list: PaymentPlanList) {
    this.list.page = list.page;
    this.list.total = list.total;
    this.list.limit = list.limit;
    this.list.items = [];
    list.items.forEach((plan: IPaymentPlan) => {
      this.list.items.push(new PaymentPlan(plan));
    });
  }

  @Mutation
  [Mutations.PAYMENT_PLANS_UPDATE_ONE](data: PaymentPlan) {
    this.plan = data;
  }

  @Mutation
  [Mutations.PAYMENT_PLANS_SUCCESS](response) {
    this.success = response;
  }

  @Mutation
  [Mutations.PAYMENT_PLANS_SET_ERROR](error) {
    this.errors = error;
  }

  @Action
  [Actions.PAYMENT_PLANS_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = this.baseUrl;
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.PAYMENT_PLANS_UPDATE_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.PAYMENT_PLANS_SET_ERROR, errors);
          this.context.commit(Mutations.PAYMENT_PLANS_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.PAYMENT_PLANS_CREATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = this.baseUrl;
      ApiService.setHeader();
      ApiService.post(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.PAYMENT_PLANS_SUCCESS, true);
          this.context.commit(Mutations.PAYMENT_PLANS_UPDATE_ONE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.PAYMENT_PLANS_SET_ERROR, errors);
          this.context.commit(Mutations.PAYMENT_PLANS_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.PAYMENT_PLANS_UPDATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = this.baseUrl + "/" + data.payment_plan;
      ApiService.setHeader();
      ApiService.put(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.PAYMENT_PLANS_SUCCESS, true);
          //this.context.commit(Mutations.PROFILING_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.PAYMENT_PLANS_SET_ERROR, errors);
          this.context.commit(Mutations.PAYMENT_PLANS_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.PAYMENT_PLANS_GET](uuid: string) {
    return new Promise<void>((resolve, reject) => {
      const resource = this.baseUrl;
      ApiService.setHeader();
      ApiService.get(resource, uuid)
        .then(({ data }) => {
          this.context.commit(Mutations.PAYMENT_PLANS_SUCCESS, true);
          this.context.commit(Mutations.PAYMENT_PLANS_UPDATE_ONE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.PAYMENT_PLANS_SET_ERROR, errors);
          this.context.commit(Mutations.PAYMENT_PLANS_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_PAYMENT_PLANS_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/payment-plans";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.PAYMENT_PLANS_UPDATE_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }
}
