import ToastService from "@/core/services/ToastService";

/**
 * decode and generate message from api response error
 * @param {Object} responseError api response error
 */
export const decodeResponseErrors = (responseError): Array<string> => {
  let messages = [] as string[];
  if (responseError.status == 422) {
    for (const _error of responseError.data.errors) {
      messages.push(_error.loc.pop() + ": " + _error.msg);
    }
  } else if(responseError.status == 403) {
    messages.push('Acceso Denegado');
  } else if(responseError.status == 401 && responseError.message && responseError.message == 'Expired JWT Token') {
    messages.push('La sesión ha expirado');
  } else {
    if (
      typeof responseError.data !== "undefined" &&
      typeof responseError.data.errors !== "undefined"
    ) {
      messages = responseError.data.errors;
    } else if (
      typeof responseError.data !== "undefined" &&
      typeof responseError.data.message !== "undefined"
    ) {
      messages.push(responseError.data.message);
    }
  }

  return messages;
};

export const displayErrors = (store, module: string): void => {
  let message = "";
  if (typeof store.state[module] !== "undefined") {
    if (typeof store.state[module].errors === "object") {
      message = Object.values(store.state[module].errors).join("<br>");
      store.state[module].errors = {};
    } else if (Array.isArray(store.state[module].errors)) {
      message = store.state[module].errors.join("<br>");
      store.state[module].errors = [];
    } else {
      message = store.state[module].errors;
      store.state[module].errors = "";
    }
  }
  ToastService.show(message, "error");
};
