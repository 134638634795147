enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  RECOVER = 'recover',
  UPDATE_PASSWORD = 'updatePassword',
  UPDATE_PASSWORD_PROFILE = 'updatePasswordProfile',
  VALIDATOR_TOKEN = 'validatorToken',
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  PAYMENT_PLANS_GET = "paymentPlansGetAction",
  PAYMENT_PLANS_LIST = "paymentPlansListAction",
  PAYMENT_PLANS_CREATE = "paymentPlansCreateAction",
  PAYMENT_PLANS_UPDATE = "paymentPlansUpdateAction",

  ORDER_LIST = "listOrders",
  ORDER_GET = "getOrder",
  ORDER_CREATE = "saveOrder",
  ORDER_UPDATE = "updateOrder",
  ORDER_UPDATE_STATE = "updateStateOrder",

  MUTUAL_GET = "getMutual",
  MUTUAL_GET_SETTINGS = "getMutualSettings",
  MUTUAL_SAVE_SETTINGS = "saveMutualSettings",
  MUTUAL_GET_CATALOG = "getMutualCatalog",
  MUTUAL_SITE_GET = "getSite",

  USERS_GET = "usersGetAction",
  USERS_LIST = "usersListAction",
  USERS_CREATE = "usersCreateAction",
  USERS_UPDATE = "usersUpdateAction",
  LOAD_DATA_PROFILE = "loadDataProfile",
  UPDATE_PROFILE = 'updateProfile',
  LOAD_DATA_PROFILE_EDIT = 'loadDataProfileEdit',

  PRODUCT_LIST = "listProducts",
  TAX_TERM_LIST = "listTaxTerms",

  CATEGORY_LIST = "categoryListAction",
  BRAND_LIST = "brandListAction",
  CATALOG_UPDATE = "catalogUpdateAction",

  //ADMIN
  ADMIN_MUTUAL_LIST = "adminListMutuales",
  ADMIN_MUTUAL_GET = "adminGetMutual",
  ADMIN_MUTUAL_CREATE = "adminSaveMutual",
  ADMIN_MUTUAL_UPDATE = "adminUpdateMutual",
  ADMIN_MUTUAL_CHANGE_STATUS = "adminUpdateStatusMutual",
  ADMIN_MUTUAL_GET_SETTINGS = "adminGetMutualSettings",
  ADMIN_MUTUAL_SAVE_SETTINGS = "adminSaveMutualSettings",
  ADMIN_MUTUAL_GET_CATALOG = "adminGetMutualCatalog",
  ADMIN_MUTUAL_TAX_TERMS_UPDATE = "adminUpdateMutualTaxTerms",
  ADMIN_LOCATION_LIST = "adminListLocations",
  ADMIN_PRODUCT_LIST = "adminListProducts",
  ADMIN_CATALOG_UPDATE = "adminCatalogUpdateAction",
  ADMIN_PAYMENT_PLANS_LIST = "adminListPaymentPlans",
  ADMIN_SITE_GET = "adminGetSite",
  ADMIN_SITE_CREATE = "adminSaveMutualSite",
  ADMIN_SITE_UPDATE = "adminUpdateMutualSite",
  ADMIN_SITE_CHANGE_STATUS = "adminChangeStatusMutualSite",
  CATALOG_PRODUCT_LIST = "catalogProductList",
  CATALOG_BRAND_LIST = "catalogBrandList",
  CATALOG_CATEGORY_LIST = "catalogCategoryList",

  TA_CONFIGURE_ACTION = "TAConfigureAction",
  TA_UPDATE_DATE_RANGE_ACTION = "TAUpdateDateRangeAction",
  TA_UPDATE_FILTER_MUTUAL_ACTION = "TAUpdateFilterMutualAction",

  DB_ORDERS_COUNT = "dashboardGetOrdersCount",
  DB_ORDERS_INCOME = "dashboardGetOrdersIncome",

}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  LOAD_USER_FROM_TOKEN = "loadUserDataFromToken",

  PAYMENT_PLANS_UPDATE_LIST = "paymentPlansUpdateListMutation",
  PAYMENT_PLANS_SET_ERROR = "paymentPlansSetErrorMutation",
  PAYMENT_PLANS_SUCCESS = "paymentPlansSuccessMutation",
  PAYMENT_PLANS_UPDATE_ONE = "paymentPlansUpdateOneMutation",

  ORDER_UPDATE_LIST = "OrderUpdateListData",
  ORDER_UPDATE_DATA = "OrderUpdateData",
  ORDER_CREATE = "OrderCreate",
  ORDER_STATE_UPDATE = "OrderStateUpdate",

  MUTUAL_UPDATE_DATA = "mutualUpdateData",
  MUTUAL_UPDATE_SETTINGS = "mutualUpdateSettings",
  MUTUAL_UPDATE_CATALOG = "mutualUpdateCatalog",
  MUTUAL_SUCCESS = "mutualSuccess",
  MUTUAL_SET_ERROR = "mutualSetError",

  SITE_UPDATE_DATA = "siteUpdateData",

  USERS_UPDATE_LIST = "usersUpdateListMutation",
  USERS_SET_ERROR = "usersSetErrorMutation",
  USERS_SUCCESS = "usersSuccessMutation",
  USERS_UPDATE_ONE = "usersUpdateOneMutation",

  RECOVER_SUCCESS = "recoverSuccessMutation",

  PRODUCT_UPDATE_LIST = "productUpdateListData",

  TAX_TERM_UPDATE_LIST = "taxtermsUpdateListData",
  TAX_TERM_SUCCESS = "taxTermsSuccess",

  CATEGORY_UPDATE_LIST = "categoryUpdateListMutation",
  CATEGORY_SUCCESS = "categorySuccessMutation",
  BRAND_UPDATE_LIST = "brandUpdateListMutation",
  BRAND_SUCCESS = "brandySuccessMutation",

  //ADMIN
  ADMIN_MUTUAL_UPDATE_LIST = "adminMutualUpdateListData",
  ADMIN_MUTUAL_UPDATE_DATA = "adminMutualUpdateData",
  ADMIN_MUTUAL_UPDATE_SETTINGS = "adminMutualUpdateSettings",
  ADMIN_MUTUAL_UPDATE_CATALOG = "adminMutualUpdateCatalog",
  ADMIN_MUTUAL_SUCCESS = "adminMutualSuccess",
  ADMIN_LOCATION_UPDATE_LIST = "adminLocationUpdateListData",
  ADMIN_PRODUCT_UPDATE_LIST = "adminProductUpdateListData",

  CATALOG_UPDATE_PRODUCTS = "catalogUpdateProducts",
  CATALOG_UPDATE_BRANDS = "catalogUpdateBrands",
  CATALOG_UPDATE_CATEGORIES = "catalogUpdateCategories",
  CATALOG_SET_ERROR = "catalogSetError",

  TA_CONFIGURE_MUTATION = "TAConfigureMutation",
  TA_UPDATE_DATE_RANGE_MUTATION = "TAUpdateDateRangeMutation",
  TA_UPDATE_FILTER_MUTUAL_MUTATION = "TAUpdateFilterMutualMutation",

  DB_UPDATE_ORDERS_COUNT = "dashboardUpdateOrdersCountMutation",
  DB_UPDATE_ORDERS_INCOME = "dashboardUpdateOrdersIncomeMutation",
}

export { Actions, Mutations };
