import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Product } from "@/core/model/Product";
import { MutualProduct } from "@/core/model/mutualProduct";
import { decodeResponseErrors } from "@/core/helpers/errors";

export interface ProductList {
  page: number;
  limit: number;
  total: number;
  items: Array<MutualProduct>;
}
export interface ProductInfo {
  list: ProductList;
  success: boolean;
  errors: Array<string>;
}

@Module
export default class ProductsModule extends VuexModule implements ProductInfo {
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  success = false;
  errors = [];

  @Mutation
  [Mutations.PRODUCT_UPDATE_LIST](list) {
    this.list = list;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Action({rawError: true})
  [Actions.PRODUCT_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/mutual/products/mutual-catalog";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
          .then(({ data }) => {
            this.context.commit(Mutations.PRODUCT_UPDATE_LIST, data);
            resolve(data);
          })
          .catch(({ response }) => {
            const errors = decodeResponseErrors(response);
            this.context.commit(Mutations.SET_ERROR, errors);
            this.context.commit(Mutations.MUTUAL_SUCCESS, false);
            reject(response);
          });
    });
  }

  @Action({rawError: true})
  [Actions.CATALOG_UPDATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/mutual/catalog";
      ApiService.setHeader();
      ApiService.put(resource, data)
          .then(({ data }) => {
            this.context.commit(Mutations.MUTUAL_SUCCESS, true);
            resolve(data);
          })
          .catch(({ response }) => {
            const errors = decodeResponseErrors(response);
            this.context.commit(Mutations.SET_ERROR, errors);
            this.context.commit(Mutations.MUTUAL_SUCCESS, false);
            reject(response);
          });
    });
  }
}
