import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {ISite, Site} from "@/core/model/Site";
import { decodeResponseErrors } from "@/core/helpers/errors";

export interface SiteList {
  page: number;
  limit: number;
  total: number;
  items: Array<Site>;
}
export interface SiteInfo {
  site: Site;
  list: SiteList;
  success: boolean;
  errors: Array<string>;
}

@Module
export default class AdminSiteModule extends VuexModule implements SiteInfo {
  site = {} as Site;
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  success = false;
  errors = [];

  @Mutation
  [Mutations.SITE_UPDATE_DATA](data) {
    if(typeof data.uuid !== "undefined"){
      this.site = new Site(data);
    } else {
      this.site = {} as Site;
    }
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Action({rawError: true})
  [Actions.ADMIN_SITE_GET](uuid) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/mutuales";
      ApiService.setHeader();
      ApiService.get(resource, uuid + "/site")
        .then(({ data }) => {
          this.context.commit(Mutations.SITE_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_SITE_CREATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/sites";
      ApiService.setHeader();
      ApiService.post(resource, data)
          .then(({ data }) => {
            this.context.commit(Mutations.SITE_UPDATE_DATA, data);
            resolve(data);
          })
          .catch(({ response }) => {
            const errors = decodeResponseErrors(response);
            this.context.commit(Mutations.SET_ERROR, errors);
            reject(response);
          });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_SITE_UPDATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/sites/" + data.mutual_site;
      ApiService.setHeader();
      ApiService.put(resource, data)
          .then(({ data }) => {
            this.context.commit(Mutations.SITE_UPDATE_DATA, data);
            resolve(data);
          })
          .catch(({ response }) => {
            const errors = decodeResponseErrors(response);
            this.context.commit(Mutations.SET_ERROR, errors);
            reject(response);
          });
    });
  }

  @Action({rawError: true})
  [Actions.ADMIN_SITE_CHANGE_STATUS](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/admin/sites/" + data.site + "/change-status";
      ApiService.setHeader();
      ApiService.put(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SITE_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors = decodeResponseErrors(response);
          this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }
}
